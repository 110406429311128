// © Copyright IBM Corp. 2022, 2024

// in src/users.js
import * as React from 'react';
import { Datagrid, List, TextField, NumberField, DateField } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { Link } from '@material-ui/core';

const UrlField = (props) => {
  const { source, target, rel } = props;
  const record = useRecordContext(props);
  const value = record && record[source];

  if (value == null) {
    return null;
  }

  return (
    <Link href={value} target={target} rel={rel} sx={{}}>
      Link
    </Link>
  );
};

export const ReportList = () => (
  <List sort={{ field: 'updatedAt', order: 'ASC' }}>
    <Card>
      <CardHeader title="Logs" />
      <CardContent>Decision logs for your organization rolled-up by day. Links expire after 5 mins of page load, reload page to get new links.</CardContent>
    </Card>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" sortable={false}  />
      <DateField source="updatedAt" sortable={false} showTime={true} />
      <NumberField source="size" options={{ style: 'unit', unit: 'byte', unitDisplay: 'narrow', notation: "compact" }} sortable={false} />
      <UrlField
        label="Download Link"
        source="url"
        target="_blank" // New window
        rel="noopener noreferrer" // For security
        sortable={false}
      />
    </Datagrid>
  </List>
);
