// © Copyright IBM Corp. 2022, 2024

import React from 'react';
import DualListBox  from 'react-dual-listbox';
import { useController } from 'react-hook-form';
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaAnglesDown, FaAnglesLeft, FaAnglesRight, FaAnglesUp, FaAngleUp } from 'react-icons/fa6';

export const MyDualListBox = (props) => {
  const { field: selectedPolicies } = useController({ name: props.source });

  const updateData = (e) => {
    selectedPolicies.onChange(e);
  };
  return (
    <DualListBox
      options={props.options}
      selected={selectedPolicies.value || []}
      onChange={(newValue) => updateData(newValue)}
      preserveSelectOrder
      showOrderButtons
      icons={{
        moveToAvailable: <FaAngleLeft />,
        moveAllToAvailable: <FaAnglesLeft />,
        moveToSelected: <FaAngleRight />,
        moveAllToSelected: <FaAnglesRight />,
        moveDown: <FaAngleDown />,
        moveUp: <FaAngleUp />,
        moveTop: <FaAnglesUp />,
        moveBottom: <FaAnglesDown />,
      }}
    />
  );
};
