// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';
import { DeleteButton, SaveButton, Toolbar, useRefresh, useRedirect } from 'react-admin';
import Button from '@mui/material/Button';
import { BulkDeleteButton } from 'react-admin';

const CancelButton = ({ redirectPath }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleClick = () => {
    redirect('show', redirectPath);
    refresh();
  };

  return (
    <Button color="primary" onClick={handleClick}>
      Cancel
    </Button>
  );
};

export const CustomToolbar = ({ redirectPath, showDelete = true }) => (
  <Toolbar sx={{ justifyContent: 'space-between' }}>
    <SaveButton label="Save" />
    <CancelButton redirectPath={redirectPath} />
    {showDelete && <DeleteButton label="Delete" />}
  </Toolbar>
);

export const BulkActionButton = (props) => (
  <React.Fragment>
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} mutationMode="pessimistic" />
  </React.Fragment>
);

// TODO: make this an API call
export const HazPerms = (permissions, resource, action) => {
  var canAccess = false;

  // resource should be set, either to something specific like policies
  // or to wildcard '*'
  if (!resource) {
    return canAccess;
  }

  // if resource is defined but not action, then the check is to see if there are
  // any settings set
  if (!action) {
    if (permissions.filter((p) => p.resource === resource).length) {
      canAccess = true;
    }
  }

  // check for wildcards
  // return true if the resource and action are *
  if (permissions.filter((p) => p.resource === '*')?.filter((p) => p.actions.includes('*')).length) {
    canAccess = true;
  }

  // check if resource is wildcard and specific action is defined
  else if (permissions.filter((p) => p.resource === '*')?.filter((p) => p.actions.includes(action)).length) {
    canAccess = true;
  }

  // check if the requested resource has wildcard for actions
  else if (permissions.filter((p) => p.resource === resource)?.filter((p) => p.actions.includes('*')).length) {
    canAccess = true;
  }

  // check for specific match on resource and action
  else if (permissions.filter((p) => p.resource === resource)?.filter((p) => p.actions.includes(action)).length) {
    canAccess = true;
  }
  return canAccess;
};
