// © Copyright IBM Corp. 2022, 2024

import { JsonEditor } from 'json-edit-react';
import React from 'react';
import { useController } from 'react-hook-form';

export const JsonEditor2 = (props) => {
  const { field } = useController({ name: props.source });

  const updateData = (e) => {
    field.onChange(e.newData)
  }
  return <JsonEditor 
    data={field.value || []} 
    defaultValue={""}
    rootName={props.rootName || ""}
    showArrayIndices={false} 
    showCollectionCount={'when-closed'} 
    collapse={({ size }) => size < 1} 
    onUpdate={updateData}
    enableClipboard={false}
    />;
};
