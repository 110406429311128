// © Copyright IBM Corp. 2022, 2024

// in App.js
import * as React from 'react';
import { Admin, CustomRoutes, defaultTheme } from 'react-admin';
import LoginPage from './page/loginPage';
import config from '../config';
import { authFactory, fetchJsonWithCookie } from '../authProvider';
import drfProvider from 'ra-data-django-rest-framework-v4';
import { Dashboard } from './page/dashboard';

const dataProvider = drfProvider(
  `${config.apiUrl}/v1/client`,
  fetchJsonWithCookie,
);

const clientDataProvider = {
  dashboard: () => {
    return fetch(`/dashboard/`, { method: 'GET' }).then((response) =>
      response.json(),
    );
  },
  ...dataProvider,
};

const clientAuthProvider = authFactory(
  `${config.apiUrl}${config.clientBasePath}`,
);

const raptorTheme = {
  ...defaultTheme,
};

const App = () => (
  <Admin
    theme={raptorTheme}
    loginPage={LoginPage}
    authProvider={clientAuthProvider}
    dataProvider={clientDataProvider}
    dashboard={Dashboard}
    requireAuth
    disableTelemetry
  >
    <CustomRoutes>
      {/*<Route path="/account" element={<Account/>}/>*/}
      {/*<Route path="/help" element={<HelpPage/>}/>*/}
    </CustomRoutes>
  </Admin>
);

export default App;
