// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  SimpleForm,
  TextField,
  TextInput,
  email,
  useUnique,
  useNotify,
  useRefresh,
  useRedirect,
  EditBase,
  Edit,
  CreateBase,
  Create,
  ListBase,
  List,
} from 'react-admin';
import { CustomToolbar, BulkActionButton } from '../component/customComponents';
import { MyBreadcrumbs } from '../component/breadcrumb';

export const EntityList = () => (
  <ListBase>
    <MyBreadcrumbs path={'/entity'} />
    <List>
      <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButton />}>
        <TextField source="id" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="username" />
        <EmailField source="email" />
        <DateField label="Date Joined" source="date_joined" type="date" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  </ListBase>
);

export const EntityCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/entity');
    refresh();
    notify(`Entity created`, { type: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path="/entity" />
      <Create {...props} mutationOptions={{ onSuccess }}>
        <EntityForm
          {...props}
          redirect="list"
          defaultValues={{
            first_name: '',
            last_name: '',
            username: '',
            email: '',
          }}
        />
      </Create>
    </CreateBase>
  );
};

export const EntityForm = (props) => {
  const validateEmail = email();
  const unique = useUnique();
  return (
    <SimpleForm {...props} toolbar={<CustomToolbar redirectPath="/entity" />}>
      <TextInput fullWidth source="first_name" required={true} />
      <TextInput fullWidth source="last_name" required={true} />
      <TextInput fullWidth source="username" validate={unique()} required={true} />
      <TextInput fullWidth source="email" validate={[validateEmail, unique()]} required={true} />
    </SimpleForm>
  );
};

export const EntityEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/entity');
    refresh();
    notify(`Changes saved`, { type: 'success' });
  };

  return (
    <EditBase>
      <MyBreadcrumbs path="/entity" />
      <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
        <EntityForm />
      </Edit>
    </EditBase>
  );
};
