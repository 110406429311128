// © Copyright IBM Corp. 2022, 2024

import './styles.css';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import * as React from 'react';
import {
  ArrayInput,
  BooleanField,
  Create,
  CreateBase,
  Datagrid,
  DateField,
  Edit,
  EditBase,
  EditButton,
  List,
  ListBase,
  NumberField,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useEditContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';

import { copyToClipboard, MyTooltip } from '../utils/utils';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionButton, CustomToolbar, HazPerms } from '../component/customComponents';

export const DomainListFrom = ({ props }) => {
  const notify = useNotify();
  const { isLoading, isFetching } = useEditContext();
  if (isLoading || isFetching) {
    return (
      <div className="container">
        <div className="loading">
          <div className="loading__letter">L</div>
          <div className="loading__letter">o</div>
          <div className="loading__letter">a</div>
          <div className="loading__letter">d</div>
          <div className="loading__letter">i</div>
          <div className="loading__letter">n</div>
          <div className="loading__letter">g</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
        </div>
      </div>
    );
  }

  const handleCopy = () => {
    const success = copyToClipboard(document.getElementById('domainID').innerHTML);
    if (success) {
      notify('Domain list ID copied to clipboard', { type: 'success', autoHideDuration: 5000 });
    }
  };

  return (
    <SimpleForm {...props} toolbar={<CustomToolbar redirectPath={'/domainlist'} />}>
      <TextInput fullWidth source="name" />
      <TextInput fullWidth source="description" />
      <div className="myDiv" onClick={handleCopy}>
        <Typography variant="caption" display={'block'}>
          List ID
        </Typography>
        <TextField disabled source="id" id="domainID" />
      </div>
      <ArrayInput label="list" source="list">
        <SimpleFormIterator fullWidth>
          <TextInput fullWidth></TextInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export const DomainListCreate = ({ props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect('/domainlist');
    refresh();
    notify(`Domain list created`, { type: 'success' });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={'/domainlist'} />
      <Create {...props} mutationOptions={{ onSuccess }}>
        <DomainListFrom
          defaultValues={{
            name: '',
            description: '',
            list: [],
          }}
        />
      </Create>
    </CreateBase>
  );
};

export const DomainListEdit = ({ props }) => (
  <EditBase>
    <MyBreadcrumbs path={'/domainlist'} />
    <Edit {...props} mutationMode="pessimistic">
      <DomainListFrom {...props} />
    </Edit>
  </EditBase>
);

export const DomainListList = () => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <ListBase>
      <MyBreadcrumbs path={'/domainlist'} />
      <List>
        <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButton />}>
          <TextField source="id" />
          <TextField source="name" />
          <DateField source="updated_at" label="Last Updated" showTime />
          <NumberField label="List Members" source="list.length" />
          {HazPerms(permissions, 'policies', 'edit') ? <EditButton label="Edit" /> : null}
        </Datagrid>
      </List>
    </ListBase>
  );
};

export const globalDomainListList = () => {
  const notify = useNotify();
  const handleCopy = (e) => {
    if ((e.target.id === 'listId') | (e.target.cellIndex === 0)) {
      const success = copyToClipboard(e.target.innerText);
      if (success) {
        notify('Domain list ID copied to clipboard', { type: 'success', autoHideDuration: 5000 });
      }
    }
  };
  return (
    <ListBase>
      <MyBreadcrumbs path={'/globaldomainlist'} />
      <List actions={false}>
        <Datagrid bulkActionButtons={false} onClick={handleCopy}>
          <MyTooltip title="click to copy" label="Feed ID">
            <TextField source="id" label="Feed ID" id="listId" />
          </MyTooltip>
          <BooleanField source="is_active" sx={{ width: '53px' }} />
          <TextField source="name" />
          <TextField source="description" />
          <TagsField label="Tags" />
          <DateField
            sx={{ display: 'inherit', width: '145px' }}
            source="updated_at"
            label="Last Updated"
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
        </Datagrid>
      </List>
    </ListBase>
  );
};

const TagsField = () => {
  const record = useRecordContext();
  return (
    <>
      {record.metadata.map((item) => (
        <Chip label={item} key={item} />
      ))}
    </>
  );
};
