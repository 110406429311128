// © Copyright IBM Corp. 2022, 2024

// in src/MyLoginPage.js
import * as React from 'react';
import { Form, Login, SaveButton, TextInput } from 'react-admin';
import {
  Box,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { Avatar, ListItemButton } from '@mui/material';

import config from '../config';
import GitHubIcon from '@mui/icons-material/GitHub';
import SecurityIcon from '@mui/icons-material/Security';
import LoginIcon from '@mui/icons-material/Login';

class LoginResolver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org: '',
      orgValid: false,
      isLoaded: false,
      auth_providers: [],
      error: null,
    };
  }

  handleChange = (e) => {
    this.setState({ org: e.target.value });
  };

  helperText = () => {
    if (this.state.error && this.state.error['orgId']) {
      return this.state.error['orgId'];
    }
    return '';
  };
  getProviders = () => {
    fetch(`${config.apiUrl}/v1/auth/begin/${this.state.org}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 404) {
          throw { orgId: 'Provided Org Has No Configured Providers' };
        }
        return res.json();
      })
      .then(
        (result) => {
          this.setState({
            orgValid: true,
            auth_providers: result.auth_providers,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: false,
            error,
          });
        },
      );
  };

  render() {
    if (!this.state.orgValid) {
      return (
        <CardContent>
          <Typography
            align="center"
            style={{ margin: 'auto' }}
            variant={'h5'}
          >
            Sign in to Raptor
          </Typography>
          <Form onSubmit={this.getProviders}>
            <TextInput
              error={this.state.error !== null}
              name="orgId"
              source="Your Organization"
              onChange={this.handleChange}
              fullWidth
              helperText={
                <Typography
                  variant={'caption'}
                  style={{ color: 'red' }}
                >
                  {this.helperText()}
                </Typography>
              }
            />
            <SaveButton
              icon={<LoginIcon />}
              label="Continue"
            />
          </Form>
        </CardContent>
      );
    } else {
      return <AuthProviderList org={this.state.org}></AuthProviderList>;
    }
  }
}

class AuthProviderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      org: this.props.org,
    };
  }

  isValidUrl = (urlString) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (e) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  logoUrlToLogo(url) {
    let targetUrl = '';
    if (this.isValidUrl(url)) {
      targetUrl = url;
    }
    switch (url) {
      // real url was supplied and its valid
      case targetUrl:
        return (
          <Avatar
            style={{ border: 0, objectFit: 'cover' }}
            src={url}
            variant={'square'}
          />
        );
      case 'github':
        return <GitHubIcon />;
      default:
        return <SecurityIcon />;
    }
  }

  componentDidMount() {
    fetch(`${config.apiUrl}/v1/auth/begin/${this.state.org}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            auth_providers: result.auth_providers,
            org_icon: result.org_icon || 'default_org_icon',
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  render() {
    const { error, isLoaded, auth_providers, org_icon } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <Box style={{ display: 'block', width: '100%', maxWidth: 360 }}>
          <CardMedia
            component={'img'}
            style={{ maxWidth: 150, margin: 'auto' }}
            src={org_icon}
          />
          <List>
            {auth_providers.map((provider) => (
              <ListItem key={provider.url}>
                <ListItemButton
                  // TODO: use the same style as react admin or change landing page
                  style={{ background: '#1976d2', borderRadius: 4 }}
                  onClick={() => (window.location.href = provider.url)}
                >
                  <ListItemIcon style={{ color: 'black' }}>
                    {this.logoUrlToLogo(provider.icon)}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography>
                        Sign in with {provider.display_name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      );
    }
  }
}

const LoginPage = () => {
  return (
    <Login
    //backgroundImage="https://source.unsplash.com/random/1600x900/daily"
    >
      <LoginResolver />
    </Login>
  );
};

export default LoginPage;
