// © Copyright IBM Corp. 2022, 2024

import { Layout, Menu, useSidebarState, useTranslate, UserMenu, Logout, AppBar, MenuItemLink, usePermissions } from 'react-admin';

import * as React from 'react';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { HazPerms } from './customComponents';


export const SubMenu = (props) => {
  const { isDropdownOpen = false, primaryText, leftIcon, children } = props;
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [isOpen, setIsOpen] = useState(isDropdownOpen);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <ListItem
        dense
        button
        onClick={handleToggle}
        sx={{
          paddingLeft: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        {isOpen ? <ExpandMoreIcon /> : leftIcon}
        <ListItemText
          inset
          disableTypography
          primary={translate(primaryText)}
          sx={{
            paddingLeft: 2,
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={
            open
              ? {
                  paddingLeft: '25px',
                  transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
              : {
                  paddingLeft: 0,
                  transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
          }
        >
          {children}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

// smells of typescript, which is
// export type SubMenuProps = {
//     children?: React.ReactNode;
//     isDropdownOpen?: boolean;
//     leftIcon?: React.ReactElement;
//     primaryText?: string;
// };

export const MyMenu = () => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <Menu>
      <Menu.DashboardItem />
      <SubMenu primaryText="ZNS" leftIcon={<ChevronRightIcon />}>
        <Menu.ResourceItem name="ca" />
        <SubMenu primaryText="Policies" leftIcon={<ChevronRightIcon />}>
          <Menu.ResourceItem name="policy_chain" />
          <Menu.ResourceItem name="policy" />
        </SubMenu>
        {/*<Menu.ResourceItem name="pdns"/>*/}
        <Menu.ResourceItem name="service" />
        {HazPerms(permissions, 'policies') && (
          <SubMenu primaryText="Domain Lists" leftIcon={<ChevronRightIcon />}>
            <Menu.ResourceItem name="domainlist" />
            <Menu.ResourceItem name="globaldomainlist" />
          </SubMenu>
        )}
        {/*<SubMenu primaryText="Advanced" leftIcon={<ChevronRightIcon/>}>*/}
        {/*    <Menu.ResourceItem name="classifier"/>*/}
        {/*    <Menu.ResourceItem name="action"/>*/}
        {/*</SubMenu>*/}
      </SubMenu>
      <SubMenu primaryText="Identities" leftIcon={<ChevronRightIcon />}>
        <Menu.ResourceItem name="entity" />
        {/*<Menu.ResourceItem name="endpoint"/>*/}
        <Menu.ResourceItem name="idp" />
        {/*<Menu.ResourceItem name="endpointproviders"/>*/}
      </SubMenu>
      <SubMenu primaryText="Settings" leftIcon={<ChevronRightIcon />}>
        <Menu.ResourceItem name="accesstoken" />
        <Menu.Item to="/help" primaryText="Help" leftIcon={<QuestionMarkIcon />} />
      </SubMenu>
      <SubMenu primaryText="Reports" leftIcon={<ChevronRightIcon />}>
      <Menu.Item to="/reports/logs" primaryText="Logs" leftIcon={<SummarizeIcon />} />
      </SubMenu>
    </Menu>
  );
};

const MyProfile = () => (
  <AppBar
    userMenu={
      <UserMenu>
        <MenuItemLink to="/profile" primaryText="My Profile" leftIcon={<SettingsIcon />} />
        <Logout />
      </UserMenu>
    }
  />
);

export const MyLayout = (props) => <Layout {...props} menu={MyMenu} appBar={MyProfile} />;
