// © Copyright IBM Corp. 2022, 2024

// in src/users.js
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { FormDataConsumer } from 'ra-core';
import * as React from 'react';
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  regex,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useCreate,
  useGetList,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
  useUnique,
  useUpdate,
} from 'react-admin';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { BulkActionButton, CustomToolbar, HazPerms } from '../component/customComponents';
import { MyDualListBox } from '../component/dualListBox';
import { JsonEditor2 } from '../component/jsonEditor2';

export const PolicyAction = () => {
  return (
    <Grid container width="100%" spacing={2} sx={{ p: 2 }}>
      <Grid item xs={8}>
        <Typography variant="h6">Action</Typography>
      </Grid>
      <Grid item xs={8} sx={{ p: 2, border: '1px dashed grey' }}>
        <SelectInput
          fullWidth
          source="action.type"
          choices={[
            { id: 'deny', name: 'Deny' },
            { id: 'intercept', name: 'Intercept' },
            { id: 'allow', name: 'Allow' },
            { id: 'forward', name: 'Forward' },
            { id: 'ns1_answer', name: 'NS1 Answer' },
            { id: 'private_authority', name: 'Private Authority' },
            { id: 'static_answer', name: 'Static Answer' },
          ]}
        />
      </Grid>
      <Grid item xs={8}>
        <FormDataConsumer>
          {({ formData }) => {
            let action = formData['action']['type'];
            if (action === 'ns1_answer') {
              return <TextInput label="NS1 Record Name" fullWidth={true} source="action.record"></TextInput>;
            } else if (action === 'forward') {
              return (
                <ArrayInput label="Resolvers" source="action.resolvers">
                  <SimpleFormIterator>
                    <TextInput></TextInput>
                  </SimpleFormIterator>
                </ArrayInput>
              );
            } else if (action === 'static_answer') {
              return (
                <ArrayInput label="Answers" source="action.hosts">
                  <SimpleFormIterator>
                    <TextInput></TextInput>
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }
          }}
        </FormDataConsumer>
      </Grid>
    </Grid>
  );
};

export const PolicyForm = (props) => {
  const [update] = useUpdate();
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const unique = useUnique();

  const save = (e) => {
    if (props.isedit) {
      update(
        'policy',
        { id: e.id, data: e },
        {
          onSuccess: () => {
            notify(`Policy saved.`, { type: 'success' });
          },
          onError: (err) => {
            notify(`Error saving policy:  ${JSON.stringify(err.body.details)}`, { type: 'error' });
          },
        },
      );
    } else {
      create(
        'policy',
        { data: e },
        {
          onSuccess: () => {
            redirect('/policy');
            refresh();
            notify(`Policy created`, { type: 'success' });
          },
        },
      );
    }
  };

  return (
    <SimpleForm
      {...props}
      mode="onBlur"
      reValidateMode="onBlur"
      toolbar={props.isedit ? <CustomToolbar /> : <CustomToolbar redirectPath="/policy" />}
      onSubmit={save}
    >
      <TextInput
        fullWidth
        source="name"
        required={true}
        validate={[unique(), regex(/^[_0-9-a-zA-Z]+$/, 'Name must consist of letters, numbers, underscores or hyphens')]}
      />
      <TextInput fullWidth source="description" />
      <BooleanInput source={'logging_config.enabled'} label="Log this policy" />
      <JsonEditor2 {...props} source="rules" rootName="Rules" />
      <PolicyAction fullWidth source="action" required={true} />
    </SimpleForm>
  );
};

export const PolicyCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect('/policy');
    refresh();
    notify(`Policy created`, { type: 'success' });
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <PolicyForm
        {...props}
        redirect="list"
        defaultValues={{
          action: { type: 'deny' },
          name: '',
          description: '',
        }}
      />
    </Create>
  );
};

export const PolicyEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/policy');
    refresh();
    notify(`Changes saved`, { type: 'success' });
  };

  return (
    <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <PolicyForm isedit="true" />
    </Edit>
  );
};

export const PolicyList = () => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <List sort={{ field: 'name', order: 'ASC' }} exporter={HazPerms(permissions, 'policies', 'export')}>
      <Card>
        <CardHeader title="Policies" />
        <CardContent>
          Policies allow you take control of DNS resolution. They are <b>deny by default</b>. In other words, all queries will be blocked unless you have an
          explicit allow-type policy.
          <p>
            These policies are executed on every DNS query received by Raptor in the order dictated by the <b>Order</b> field below. For each policy, the rules
            defined are executed, taking the context of the current query and elements of identity into account. (This is called the <b>Session Context</b> and
            is described in more detail in the Help page). The first policy&apos;s rule that sets the variable <code>match</code> to <code>true</code> has its{' '}
            <b>Action</b> setting evaluated and the processing of that query ends.
          </p>
        </CardContent>
      </Card>
      {HazPerms(permissions, 'advanced_policies', 'read') ? (
        <Datagrid
          rowClick="expand"
          expand={HazPerms(permissions, 'advanced_policies', 'edit') ? <PolicyEdit record={(record) => record} /> : null}
          bulkActionButtons={HazPerms(permissions, 'advanced_policies', 'edit') ? <BulkActionButton /> : null}
        >
          <TextField source="id" />
          <TextField source="name" sortBy="name" />
          <TextField source="description" />
          <BooleanField source={'logging_config.enabled'} label="Results Logged" sortable={false} />
        </Datagrid>
      ) : null}
    </List>
  );
};

export const PolicyChainList = () => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <List sort={{ field: 'name', order: 'ASC' }} exporter={HazPerms(permissions, 'policies', 'export')}>
      <Card>
        <CardHeader title="Policy Chains" />
        <CardContent>
          Policy chains allow you chain (order) multiple policies together. The policies will be evaluated in the order they are listed.
        </CardContent>
      </Card>
      {HazPerms(permissions, 'advanced_policies', 'read') ? (
        <Datagrid
          rowClick="expand"
          expand={HazPerms(permissions, 'advanced_policies', 'edit') ? <PolicyChainEdit record={(record) => record} /> : null}
          bulkActionButtons={HazPerms(permissions, 'advanced_policies', 'edit') ? <BulkActionButton /> : null}
        >
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
        </Datagrid>
      ) : null}
    </List>
  );
};

export const PolicyChainEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/policy_chain');
    refresh();
    notify(`Changes saved`, { type: 'success' });
  };

  return (
    <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <PolicyChainForm isedit="true" />
    </Edit>
  );
};

export const PolicyChainForm = (props) => {
  const [update] = useUpdate();
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const unique = useUnique();

  const { data: policies, isLoading } = useGetList('policy');
  if (isLoading) {
    return;
  }

  const policy_data = policies.map((p) => {
    return { value: p.id, label: p.name };
  });

  const save = (e) => {
    if (props.isedit) {
      update(
        'policy_chain',
        { id: e.id, data: e },
        {
          onSuccess: () => {
            notify(`Policy Chain saved.`, { type: 'success' });
          },
          onError: (err) => {
            notify(`Error saving policy:  ${JSON.stringify(err.body.details)}`, { type: 'error' });
          },
        },
      );
    } else {
      create(
        'policy_chain',
        { data: e },
        {
          onSuccess: () => {
            redirect('/policy_chain');
            refresh();
            notify(`Policy Chain created`, { type: 'success' });
          },
        },
      );
    }
  };

  return (
    <SimpleForm
      {...props}
      mode="onBlur"
      reValidateMode="onBlur"
      toolbar={props.isedit ? <CustomToolbar /> : <CustomToolbar redirectPath="/policy_chain" />}
      onSubmit={save}
    >
      <TextInput
        fullWidth
        source="name"
        required={true}
        validate={[unique(), regex(/^[_0-9-a-zA-Z]+$/, 'Name must consist of letters, numbers, underscores or hyphens')]}
      />
      <TextInput fullWidth source="description" />
      <Typography variant="h6">Policies</Typography>
      <Grid container width="100%" sx={{ p: 1 }}>
        <Grid item xs={6}>
          <Typography variant="h6">Available</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ paddingLeft: '10px' }}>
            Selected
          </Typography>
        </Grid>
      </Grid>
      <Grid container width="100%">
        <Grid item xs={12}>
          <MyDualListBox source="policies" options={policy_data} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const PolicyChainCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect('/policy_chain');
    refresh();
    notify(`Policy Chain created`, { type: 'success' });
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <PolicyChainForm {...props} redirect="list" />
    </Create>
  );
};
