// © Copyright IBM Corp. 2022, 2024

// in profile.js
import React from 'react';
import { Title, SimpleShowLayout, TextField, useGetIdentity, LoadingIndicator } from 'react-admin';
import { Card, CardContent, Container } from '@mui/material';

export const ProfileShow = () => {
  const { isLoading: loading, data: identity, error } = useGetIdentity();

  if (error) {
    return null;
  }
  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <Title title="My Profile" />
      <Card>
        <CardContent>
          <SimpleShowLayout record={identity ? identity : {}}>
            <TextField source="id" label="ID"/>
            <TextField source="username" />
            <TextField source="fullName" label="Full Name"/>
            <TextField source="orgID" label="Org ID"/>
            <TextField source="orgName" label="Org Name"/>
            <TextField source="orgLabel" label="Org Label" />
            <TextField source="orgDisplayName" label="Org Display Name" />
            <TextField source="email" />
          </SimpleShowLayout>
        </CardContent>
      </Card>
    </Container>
  );
};
