// © Copyright IBM Corp. 2022, 2024

import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export function copyToClipboard(textToCopy) {
  var success = false;

  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(textToCopy);
    success = true;
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
      success = true;
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
  return success;
}

// TooltipWrapper gets initial MyTooltip props without Tooltip props
// and passes them to children (an input component in this example)
const TooltipWrapper = React.forwardRef(function TooltipWrapper({ children, ...props }, ref) {
  return (
    <div {...props} ref={ref}>
      {React.cloneElement(children, props)}
    </div>
  );
});

// MyTooltip gets explicit props and props added by SimpleForm
// and passes them together to the Tooltip.
export const MyTooltip = ({ children, ...props }) => (
  <Tooltip {...props}>
    <TooltipWrapper>{children}</TooltipWrapper>
  </Tooltip>
);
