// © Copyright IBM Corp. 2022, 2024

import Cookies from 'js-cookie';
import { fetchUtils } from 'ra-core';
import config from './config';

export function fetchJsonWithCookie(url, options) {
  options = options || {};
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('X-CSRFToken', Cookies.get(config.csrfCookieName, ''));
  if (options['method'] === 'PATCH') {
    options['method'] = 'PUT';
  }
  return fetchUtils.fetchJson(
    url,
    Object.assign(
      {
        credentials: 'include',
      },
      options,
    ),
  );
}

export function authFactory(baseURL) {
  const authProvider = {
    // authentication
    login: () => {
      return Promise.resolve(/* ... */);
    },
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      return fetch(`${baseURL}/session/`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 401 || res.status === 403) {
          return Promise.reject({ message: res.statusText ? res.statusText : 'Login failed' });
        } else {
          return Promise.resolve();
        }
      });
    },
    logout: () => {
      return fetch(`${baseURL}/logout/`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 401 || res.status === 403 || res.status === 200) {
          return Promise.resolve();
        }
      });
    },
    getIdentity: () => {
      return fetch(`${baseURL}/userInfo/`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data['fullName'].replace(' ', '') === '') {
            data['fullName'] = data['email'] ? data['email'].split('@')[0] : data['username'];
          }
          return data;
        });
    },
    // authorization
    getPermissions: () => {
      return fetch(`${baseURL}/featureFlags/`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          return data.results;
        });
    },
  };
  return authProvider;
}
